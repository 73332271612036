"use client";

import ErrorCustom from "~components/customPages/ErrorCustom";
import ErrorBoundary from "~components/error/ErrorBoundary";
export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	// return <ErrorBoundary error={error} reset={reset}></ErrorBoundary>;
	return (
		<>
			<ErrorCustom  from="global" error={error} reset={reset} />;
		</>
	);
}
