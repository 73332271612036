"use client"; // Error components must be Client Components
import React from "react";
import { useEffect } from "react";
import ImageConstant from "@/common/constants/image";
import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";

import useBrowerId from "@/lib/hooks/useBrowerId";
import useCustomer from "@/lib/hooks/useCustomer";
import useTimeServer from "@/lib/hooks/useTimeServer";
import {
  ErrorType,
  postErrorToSystemEvents,
} from "@/lib/api/shop/systemEvents";
type Props = {
  error?: Error & {
    degest?: string | undefined;
  };
  reset?: () => void;
  from: ErrorType;
};

export default function ErrorCustom({ error, reset, from }: Props) {
  const { data: browerId } = useBrowerId();
  const browerID = browerId!;
  const { data: customerInfo, isLoading: isLoadingLogged } = useCustomer();

  const { data: time } = useTimeServer();

  const getLocalIP = async () => {
    const data = await fetch("/api/ip");
    const { ip } = await data.json();
    return ip as string | undefined;
  };

  async function sendMailError(error: {
    message: string;
    name: string;
    cause: unknown;
    stack?: string | undefined;
    degest?: string | undefined;
  }) {
    const ip = await getLocalIP()
    await postErrorToSystemEvents({
      action: "error_on_page",
      company_id: 0,
      details: {
        name : error.name,
        ip: ip,
        location: `web-${from}`,
        message: error.message,
        time: time?.data.time_server || new Date().getTime() / 1000,
        tracing: JSON.stringify(error.stack),
      },
      event: "error",
      resource_id: customerInfo ? customerInfo.id.toString() : browerID,
      resource_type: "web",
    });
  }

  useEffect(() => {
    if (error?.message) {
      const data = {
        ...error,
        message: error.message,
        name: error.name,
        cause: error?.cause,
        stack: error.stack,
      };
      sendMailError(data);
    }
  }, [error?.message]);

  return (
    <div className="w-full min-h-[100vh] flex flex-col justify-center items-center gap-6 md:gap-8 md:container mx-auto p-4">
      <Image
        src={"/assets/images/page500.png"}
        alt={"img"}
        width={1500}
        height={1500}
        className="w-full max-h-[200px] sm:max-h-[350px] 2xl:max-h-[500px] object-contain"
        placeholder={"blur"}
        blurDataURL={ImageConstant.BlurDataURL}
      />
      <p className="font-medium text-sm md:text-xl text-gray-500 text-center">
        Rất tiếc, webiste đang tạm thời gián đoạn. Quý khách vui lòng truy cập
        lại sau.
      </p>
      <LinkElement
        href={"/"}
        className="bg-red-500 text-white py-2 px-5 text-sm flex items-center justify-center w-[182px] md:w-[293px] rounded border border-x-red-500 whitespace-nowrap"
      >
        Quay về trang chủ
      </LinkElement>
    </div>
  );
}
